<template>
  <router-view />
</template>
<script>
export default {
  name: 'Zz',
};
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: 100%;
  background-color: #f0efed;
}
</style>
